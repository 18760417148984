import { useState } from 'react';

import { Auth } from 'aws-amplify';
import { P, match } from 'ts-pattern';

import { MILLISECONDS_IN_A_DAY, REFRESH_TOKEN_EXPIRY_STORAGE_KEY } from '../constants';
import { AuthStages, AuthState, MfaDestination, MfaDestinations } from '../types/AuthState';
import shouldRefreshToken from '../utils/shouldRefreshToken';
import {
  handleInitialChallenge,
  handleInsecureChallenge,
  handleMfaCodeChallenge,
  handleMfaSelectionChallenge,
  handleRefreshToken,
  handleTenantChallenge
} from './challengeHandlers';

const USERNAME_SUFFIX = '@neon.com';

export const useAuthFlow = ({
  tenantId,
  agentConfig
}: {
  tenantId: string;
  agentConfig: connect.AgentConfiguration;
}) => {
  const [authState, setAuthState] = useState<AuthState>({ stage: AuthStages.initial });
  const [invalidCode, setInvalidCode] = useState(false);

  const [mfaDestinations, setMfaDestinations] = useState<{
    obfuscatedEmail: string | undefined;
    obfuscatedPhone: string | undefined;
  } | null>(null);

  const username = `${tenantId}__${agentConfig?.username}${
    !agentConfig?.username.includes('@') ? USERNAME_SUFFIX : ''
  }`;

  // Auth step handlers will call back into handleAuthStep until user input is needed (mfa_select and mfa_entry)
  // at which point handle auth step will return and terminate, handing back control to React to set the auth stage
  // and allow the UI to collect more details. Needs work on naming of stages to make clearer when it should call back
  // into handleAuthStep, and when it should return.
  const handleAuthStep = async (state: AuthState): Promise<AuthState> => {
    console.log('NNDEBUG handleAuth', state);
    return match(state)
      .returnType<Promise<AuthState> | AuthState>()
      .with({ stage: AuthStages.initial }, handleInitialChallenge({ handleAuthStep, username }))
      .with({ stage: AuthStages.insecure }, handleInsecureChallenge({ handleAuthStep, agentConfig }))
      .with({ stage: AuthStages.refresh_token }, handleRefreshToken({ handleAuthStep, tenantId }))
      .with({ stage: AuthStages.tenant_id }, handleTenantChallenge({ handleAuthStep, tenantId, setMfaDestinations }))
      .with({ stage: AuthStages.mfa_selected }, handleMfaSelectionChallenge({ handleAuthStep, tenantId }))
      .with({ stage: P.union(AuthStages.mfa_select, AuthStages.mfa_entry, AuthStages.complete_auto) }, (state) => state)
      .with({ stage: AuthStages.mfa_entered }, handleMfaCodeChallenge({ handleAuthStep, setInvalidCode, tenantId }))
      .with(
        {
          stage: P.union(AuthStages.complete_mfa, AuthStages.complete_insecure, AuthStages.complete_refresh_token)
        },
        (state) => {
          const thirtyDaysFromNow = Date.now() + 30 * MILLISECONDS_IN_A_DAY;
          localStorage.setItem(REFRESH_TOKEN_EXPIRY_STORAGE_KEY, thirtyDaysFromNow.toString());

          return state;
        }
      )
      .exhaustive();
  };

  const beginAuth = async () => {
    try {
      const authSession = await Auth.currentSession();

      if (authSession && !shouldRefreshToken()) {
        handleAuthStep({ stage: AuthStages.complete_auto });
        setAuthState({ stage: AuthStages.complete_auto });
        return;
      }
    } catch {}

    const newState = await handleAuthStep({ stage: AuthStages.initial });
    setAuthState(newState);
  };

  const selectMfaDestination = async (mfaSelection: MfaDestination) => {
    const newState = await handleAuthStep(
      match({ mfaSelection, authState })
        .with(
          { authState: { stage: AuthStages.mfa_select }, mfaSelection: MfaDestinations.email },
          ({ mfaSelection, authState: { obfuscatedEmail, authResponse } }) => ({
            authResponse,
            stage: AuthStages.mfa_selected,
            mfaSelection,
            obfuscatedEmail
          })
        )
        .with(
          { authState: { stage: AuthStages.mfa_select }, mfaSelection: MfaDestinations.sms },
          ({ mfaSelection, authState: { obfuscatedPhone, authResponse } }) => ({
            authResponse,
            stage: AuthStages.mfa_selected,
            mfaSelection,
            obfuscatedPhone
          })
        )
        .otherwise(() => {
          throw `Invalid auth state: ${authState}`;
        })
    );
    setAuthState(newState);
  };

  const provideMfaCode = async (mfaCode: string) => {
    if (!('authResponse' in authState) || !('mfaSelection' in authState)) {
      throw `Invalid auth state: ${authState}`;
    }

    setInvalidCode(false);

    const newState = await handleAuthStep({ ...authState, stage: AuthStages.mfa_entered, mfaCode });
    setAuthState(newState);

    if (newState.stage === 'mfa_entry') {
      setInvalidCode(true);
      throw 'Invalid code';
    }
  };

  return {
    beginAuth,
    selectMfaDestination,
    provideMfaCode,

    mfaDestinations,
    mfaSelection: 'mfaSelection' in authState ? authState.mfaSelection : undefined,
    invalidCode,
    authStage: authState.stage
  };
};
