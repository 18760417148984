import { ReactElement, useEffect, useMemo, useState } from 'react';

import { faWandMagicSparkles } from '@fortawesome/pro-regular-svg-icons';
import cx from 'classnames';
import _debounce from 'lodash.debounce';

import { Permissions } from '@cloud-wave/neon-common-lib';

import { useConfigContext } from 'lib/core/config';
import { useAuthContext } from 'lib/core/context/AuthProvider';

import { useContactContext } from 'lib/common/contexts/ContactContext';
import { usePermissionsContext } from 'lib/common/contexts/PermissionsContext';
import { useWrapUpCodeContext } from 'lib/common/contexts/WrapUpCodeContext';
import { useLayout } from 'lib/common/contexts/layout/LayoutContext';

import useHasOutcomesAccess from 'lib/common/hooks/useHasOutcomesAccess';

import Accordion from 'lib/common/components/Accordion';
import HeadingLabel from 'lib/common/components/HeadingLabel';
import { Input, TextAreaWithAction } from 'lib/common/components/Input';

import WRAP_UP_CODE_TYPES from 'lib/common/constants/ACW/wrapUpCodeTypes';
import chatEvents from 'lib/common/constants/chatEvents';
import contactStates from 'lib/common/constants/contactStates';
import CONTACT_TYPES from 'lib/common/constants/contactTypes';

import TChatTask from 'lib/common/types/ChatTask';
import TaskContentTypes from 'lib/common/types/TaskContentTypes';

import ACWCodeSelector from '../ACWCodeSelector';
import generateNotesAISummary from './api/generateNotesAISummary';
import DISPLAY_LABEL from './constants/displayLabel';

import './acw-outcome.scss';

interface IACWOutcomeProps {
  withoutAccordion?: boolean;
  accordionDefaultOpen?: boolean;
  className?: string;
}

const UPDATE_TASK_CONTENT_DEBOUNCE_MS = 500;

export default function ACWOutcome({ withoutAccordion, accordionDefaultOpen, className }: IACWOutcomeProps) {
  const { isSoftphone } = useLayout();
  const { hasPermission } = usePermissionsContext();
  const { fetch_ } = useAuthContext();
  const { config } = useConfigContext();

  const {
    actions: { setTaskContent },
    state: { tasks, selectedTaskId }
  } = useContactContext();

  const {
    state: { queueWrapUpCodes, loaded },
    actions: { fetchWrapUpCodes }
  } = useWrapUpCodeContext();

  const selectedTask = tasks.find((task) => task.taskId === selectedTaskId);

  const isInACW = selectedTask?.status === contactStates.ACW;

  const [notes, setNotes] = useState(selectedTask?.ACW?.notes || '');

  const canAgentAccessOutcomes = useHasOutcomesAccess(selectedTask);

  const canAgentSkipACW = !hasPermission({
    type: 'tenant',
    permission: Permissions.AGENT_ACW_CANT_SKIP
  });

  const notesAreRequired = hasPermission({
    type: 'tenant',
    permission: Permissions.AGENT_ACW_NOTES_REQUIRED
  });

  const canGenerateAISummary =
    hasPermission({
      type: 'tenant',
      permission: Permissions.AGENT_INTERACTION_CHAT_ANALYSIS
    }) && selectedTask?.type === CONTACT_TYPES.CHAT;

  // Ignore all groups and check if there are any codes for the queue
  const hasQueueWrapUpCodes =
    loaded && queueWrapUpCodes.some((wrapUpCode) => wrapUpCode.type === WRAP_UP_CODE_TYPES.CODE);

  const handleUpdatedNotes = useMemo(
    () =>
      _debounce((notes: string) => {
        setTaskContent(selectedTaskId, TaskContentTypes.ACW, { ...selectedTask?.ACW, notes: notes || void 0 });
      }, UPDATE_TASK_CONTENT_DEBOUNCE_MS),
    [selectedTask?.ACW]
  );

  useEffect(() => {
    if (!canAgentAccessOutcomes) {
      return;
    }

    fetchWrapUpCodes();
  }, []);

  useEffect(() => {
    setNotes(selectedTask?.ACW?.notes || '');
  }, [selectedTask?.taskId]);

  if (!selectedTask || !canAgentAccessOutcomes) {
    return null;
  }

  const onNotesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNotes(event.target.value);

    handleUpdatedNotes(event.target.value);
  };

  const onGenerateSummary = async () => {
    if (selectedTask.type !== CONTACT_TYPES.CHAT) {
      return;
    }

    const chatTask = selectedTask as TChatTask;

    const joinEventMessageTime = chatTask.messages.find((message) => {
      return message?.Type === 'EVENT' && message?.ContentType === chatEvents.PARTICIPANT_JOINED;
    })?.AbsoluteTime;

    const normalisedMessages = chatTask.messages.reduce(
      (acc: string[], { ParticipantRole, Type, Content, AbsoluteTime }) => {
        if (
          ParticipantRole === 'SYSTEM' ||
          Type !== 'MESSAGE' ||
          // Check if message date is after joined event or if no joined event, then include all messages
          !(new Date(AbsoluteTime) > (joinEventMessageTime ? new Date(joinEventMessageTime) : new Date(0)))
        ) {
          return acc;
        }

        return [...acc, `${ParticipantRole}: ${Content}`];
      },
      []
    );

    const summary = await generateNotesAISummary({
      fetch_,
      config,
      prompt: `You are a bot for generating after chat work notes for a contact centre interaction between an agent and a customer. Agent should be in first person, from their perspective. Do not use quotes. Summarise the following chat, using I instead of agent \n${JSON.stringify(
        normalisedMessages.join('\n')
      )}`
    });

    setNotes(summary);
    setTaskContent(selectedTaskId, TaskContentTypes.ACW, { ...selectedTask?.ACW, notes: summary });
  };

  const wrapInputElement = ({
    title,
    status,
    component
  }: {
    title: string;
    status?: string;
    component: ReactElement;
  }) => {
    const headingLabelEl = <HeadingLabel primary={title} secondary={status} smallTitle noMargin />;
    const id = `outcome-${title.replaceAll(' ', '-').toLowerCase()}`;

    if (withoutAccordion) {
      return (
        <div data-testid={id} className="acw-outcome__item">
          {headingLabelEl}
          {component}
        </div>
      );
    }

    return (
      <Accordion
        ariaLabel={`${title} ${status || ''} accordion`}
        small
        title={headingLabelEl}
        className="acw-outcome__accordion"
        defaultOpen={accordionDefaultOpen}
      >
        {component}
      </Accordion>
    );
  };

  const wrapCodeStatus = canAgentSkipACW ? DISPLAY_LABEL.OPTIONAL : DISPLAY_LABEL.REQUIRED;
  const notesStatus =
    !canAgentSkipACW && !hasQueueWrapUpCodes && notesAreRequired ? DISPLAY_LABEL.REQUIRED : DISPLAY_LABEL.OPTIONAL;

  const notesInput =
    canGenerateAISummary && isInACW ? (
      <TextAreaWithAction
        onActionClick={onGenerateSummary}
        buttonTestId="generate-ai-summary-button"
        buttonIcon={faWandMagicSparkles}
        buttonTooltip="Generate AI Summary"
        onInputChange={onNotesChange}
        placeholder="More information about this interaction"
        value={notes}
        inputClassName="acw-outcome__notes"
      />
    ) : (
      <Input
        className="acw-outcome__notes"
        multiline
        minRows={4}
        placeholder="More information about this interaction"
        maxRows={8}
        value={notes}
        onChange={onNotesChange}
      />
    );

  // We don't show optional/required unless the agent is in ACW, as it's always optional during an interaction
  return (
    <div className={cx('acw-outcome', { 'acw-outcome--softphone': isSoftphone, 'mb-20': !isSoftphone }, className)}>
      {wrapInputElement({
        title: 'Wrap Up Code',
        status: hasQueueWrapUpCodes && isInACW ? wrapCodeStatus : void 0,
        component: (
          <ACWCodeSelector
            selectedTask={selectedTask}
            queueWrapUpCodes={queueWrapUpCodes}
            loaded={loaded}
            className={isSoftphone ? 'mt-10' : void 0}
          />
        )
      })}
      {wrapInputElement({
        title: isSoftphone ? 'Notes' : 'Interaction Notes',
        status: isInACW ? notesStatus : void 0,
        component: notesInput
      })}
    </div>
  );
}
